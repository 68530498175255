<template>
    <KCourse loader-class="MBcont" course-id="2" title="Анатомия тела" :items="items">
        <div class="MBtextcont">
            <h2>Перед</h2>
            <p>Лепить "её" или нет, вот в чем вопрос... Надеюсь вы догадались, о чем пойдет речь... Нет, это не грудь ) Смотрите урок!</p>
            <p class='nomargin'><b>На этом занятии нам понадобятся:</b></p>
            <ol>
                <li>Пластик La Doll</li>
                <li>Кисть синтетическая, плоская</li>
                <li>Стеки</li>
                <li>Карандаш</li>
                <li>Чертеж</li>
                <li>Надфиля, шкурка разной зернистости</li>
                <li>Макетный нож</li>
                <li>Следок</li>
                <li>Вода</li>
            </ol>   
        </div>

        <VideoView video-id="fbe8ba8035cd49c49c0fb19634840dee"/>

        <div class="MBtextcont">
            
            <h2>Спина</h2>
            <p>Попка бабочкой! Вот с таким чудесным настроением мы проведем сегодня наше занятие! Оно, на удивление, вышло совсем коротким. Всё как вы любите, мало слов и много "тела"!</p>
            <p class='nomargin'><b>На этом занятии нам понадобятся:</b></p>
            <ol>
                <li>Пластик La Doll</li>
                <li>Кисть синтетическая, плоская</li>
                <li>Стеки</li>
                <li>Карандаш</li>
                <li>Чертеж</li>
                <li>Надфиля, шкурка разной зернистости</li>
                <li>Макетный нож</li>
                <li>Следок</li>
                <li>Вода</li>
            </ol>   
        </div>

        <VideoView video-id="def65ae4d9d7417da4897f4ae2a2f98f"/>
        

        <div class="MBtextcont">
            <h2>Дорабатываем детали</h2>
            <p>Итак, последнее занятие. Разбор полетов и маленькие хитрости которые помогут сделать тело куклы более выразительным!</p>
            <p class='nomargin'><b> На этом занятии нам понадобятся:</b></p>
            <ol>
                <li>Пластик La Doll</li>
                <li>Кисть синтетическая, плоская</li>
                <li>Стеки</li>
                <li>Карандаш</li>
                <li>Чертеж</li>
                <li>Надфиля, шкурка разной зернистости</li>
                <li>Макетный нож</li>
                <li>Следок</li>
                <li>Вода</li>
            </ol>   
        </div>

        <VideoView video-id="6d8d6071611340058ee84ea9bababbc6"/>

        <div class="MBtextcont">
            <p class='nomargin'><b>Задание:</b></p>
            <p>Доработать тело.</p>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Готовим трафареты", url:"/mycourses/body/1"},
                { title: "Заготовки из пластилина", url:"/mycourses/body/2"},
                { title: "Подготовка к обтяжке", url:"/mycourses/body/3"},
                { title: "Обтяжка пластиком", url:"/mycourses/body/4"},
                { title: "Достаем пластилин", url:"/mycourses/body/5"},
                { title: "Основа кисти", url:"/mycourses/body/6"},
                { title: "Основа стопы", url:"/mycourses/body/7"},
                { title: "Анатомия руки", url:"/mycourses/body/8"},
                { title: "Анатомия кисти", url:"/mycourses/body/9"},
                { title: "Анатомия ноги", url:"/mycourses/body/10"},
                { title: "Анатомия стоп", url:"/mycourses/body/11"},
                { title: "Анатомия тела", url:"/mycourses/body/12"},
            ],
        }
        }
    }
</script>